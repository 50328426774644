import gql from 'graphql-tag'
import { appClient } from '@/plugins/vue-apollo.plugin'
import { CreateOrderDoorConfigurationResult, UpdateOrderDoorConfigurationInput, UpdateOrderDoorConfigurationResult, DeleteOrderDoorConfigurationResult, Object_OrderDoorConfiguration } from '../schema/app.graphql.types'
import { Configuration, ConfigurationExport, ConfigurationImport } from '@/models/order/Configuration'
import { CreateProcedure, UpdateProcedure } from '@/api/graphql/schema/generic.types'
import { RngKey6 } from '@/common/utils/PRNGFunctions'
import { ulog } from '@/common/utils/AppConsole'
import { PimcoreNodeImport, PimcoreNodeStrip } from '@/models/pimcore/PimcoreNode'
import { VueGQLType } from '@/common/custom/vue-apollo-customization'
import { useCastObject } from '@/common/componentHelpers/useCastObject'

// Serves as an interface for Order update and Create
// This is to prevent crashes when return (query) values are being used for update or create (mutations).
const UpdateOrderDoorConfigurationInputObject: UpdateOrderDoorConfigurationInput = {
  key: '',
  name: '',
  quantity: 0,
  note: '',
  installationDate: '',
  installationMorning: false,
  installationAfternoon: false,
  installationEvening: false,
  workerGroup: null,
  protocolData: [null],
  directSellTransport: '',
  shippingStreet: '',
  shippingStreetNumber: '',
  shippingCity: '',
  shippingPostcode: '',
  street: '',
  streetNumber: '',
  city: '',
  postcode: '',
  floor: '',
  elevator: '',
  parameters: '',
  additionalServices: '',
  otherAdditionalServices: '',
  priceItems: '',
  attachments: [null],
  attachmentFolderId: 0,
  documents: [null],
  documentFolderId: 0,
  tags: [null],
}

export namespace GQLConfiguration {

  export namespace Read {

    const query = gql`
      query OrderDoorConfigurationRead($id: Int!) {
        getOrderDoorConfiguration(id: $id) {
          id, key, fullpath,
          name, directSellTransport, quantity, note,
          shippingStreet, shippingStreetNumber, shippingCity, shippingPostcode,
          street, streetNumber, city, postcode, floor, elevator,
          parameters, additionalServices, otherAdditionalServices, priceItems,
          installationDate, installationState, installationMorning, installationAfternoon, installationEvening,

          protocolData {
            ...on object_ProtocolData {
              id, key, fullpath,
              serialNumber, peepholeHeight, doorHeight, confirmations, clientNotes,
              protocolCreation, authorizedPerson, authorizedPersonID,

              protocol { ...on asset { id, fullpath, filename, filesize, type, mimetype } },
              signatureClient { ...on asset { id, fullpath, filename, filesize, type, mimetype } },
              signatureWorker { ...on asset { id, fullpath, filename, filesize, type, mimetype } },

              isMagnum, isCertified,
            }
          },

          attachments { ... on asset { id, fullpath, filename, type } },
          attachmentFolderId,
          documents { ... on asset { id, fullpath, filename, type } },
          documentFolderId,

          serviceRequestId,
          serviceRequest {
            ... on object_ServiceRequest {
              id, key, fullpath
              requestNumber
            }
          }

          workerGroup {
            ... on object_WorkerGroup {
              id, key, fullpath,
              workerGroupID, availableMorning, availableAfternoon, , availableEvening,

              leadingWorker {
                ...on object_Worker {
                  id, key, fullpath,
                  firstName, lastName, phone, email
                }
              },

              workers {
                ...on object_Worker {
                  id, key, fullpath,
                  firstName, lastName, phone, email
                }
              },
            }
          }
        }
      }
    `

    export type Variables = {
      id: number
    }

    export type Data = {
      getOrderDoorConfiguration: Object_OrderDoorConfiguration
    }

    export const execute = async (variables: Variables) => appClient
      .query<Data>({ query, variables, fetchPolicy: 'no-cache' })
      .then(r => r.data.getOrderDoorConfiguration
        ? ConfigurationImport(r.data.getOrderDoorConfiguration)
        : ulog('e', 'GQLConfiguration(R) failed', r)
      )
      .catch(e => ulog('e', 'GQLConfiguration(R) exception', e))

    export const procedure = async (configID?: string) => configID ? execute({ id: parseInt(configID) }) : undefined

  }

  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---
  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---
  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---

  export namespace Create {
    export type Type = VueGQLType<Data, Variables>

    const mutation = gql`
      mutation OrderDoorConfigurationCreate($key: String!, $parentId: Int!, $values: UpdateOrderDoorConfigurationInput!) {
        createOrderDoorConfiguration(key: $key, parentId: $parentId, published: true, input: $values) {
          success, output { id, key, fullpath }
        }
      }
    `

    export type Variables = {
      key: string
      parentId: number
      values: UpdateOrderDoorConfigurationInput
    }

    export type Data = {
      createOrderDoorConfiguration: CreateOrderDoorConfigurationResult
    }

    export const keyGen = (parentKey: string) => `${parentKey}-conf-${RngKey6()}`

    export const execute = async (variables: Variables) => {
      const { castObject } = useCastObject()

      const variablesNormalized = {
        key: variables.key,
        parentId: variables.parentId,
        values: castObject(variables.values, UpdateOrderDoorConfigurationInputObject),
      }

      return appClient
        .mutate<Data, Variables>({ mutation, variables: variablesNormalized })
        .then(r => r?.data?.createOrderDoorConfiguration?.success && r.data.createOrderDoorConfiguration.output
          ? PimcoreNodeImport(r.data.createOrderDoorConfiguration.output)
          : ulog('e', 'GQLConfiguration(C) failed', r)
        )
        .catch(e => ulog('e', 'GQLConfiguration(C) exception', e))
    }

    export const procedure: CreateProcedure<Configuration> = (key, parentId, data) =>
      execute({ key, parentId, values: ConfigurationExport(data) })

  }

  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---
  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---
  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---

  export namespace Update {
    export type Type = VueGQLType<Data, Variables>

    const mutation = gql`
      mutation OrderDoorConfigurationUpdate($fullpath: String!, $values: UpdateOrderDoorConfigurationInput!) {
        updateOrderDoorConfiguration(fullpath: $fullpath, input: $values) {
          success, output { id, key, fullpath }
        }
      }
    `

    export type Variables = {
      fullpath: string
      values: UpdateOrderDoorConfigurationInput
    }

    export type Data = {
      updateOrderDoorConfiguration: UpdateOrderDoorConfigurationResult
    }

    export const execute = (variables: Variables) => {
      const { castObject } = useCastObject()

      const variablesNormalized = {
        fullpath: variables.fullpath,
        values: castObject(variables.values, UpdateOrderDoorConfigurationInputObject),
      }

      return appClient
        .mutate<Data, Variables>({ mutation, variables: variablesNormalized })
        .then(r => r.data?.updateOrderDoorConfiguration.success && r.data.updateOrderDoorConfiguration.output
          ? PimcoreNodeImport(r.data.updateOrderDoorConfiguration.output)
          : ulog('e', 'GQLConfiguration(U) failed', r)
        )
        .catch(e => ulog('e', 'GQLConfiguration(U) exception', e))
    }

    export const procedure: UpdateProcedure<Configuration> = data => execute({
      fullpath: data.fullpath!,
      values: ConfigurationExport(PimcoreNodeStrip<Configuration>(data)),
    })

  }

  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---
  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---
  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---

  export namespace Delete {
    export type Type = VueGQLType<Data, Variables>

    const mutation = gql`
      mutation OrderDoorConfigurationDelete($fullpath: String!) {
        deleteOrderDoorConfiguration(fullpath: $fullpath) {
          success, message
        }
      }
    `

    export type Variables = {
      fullpath: string
    }

    export type Data = {
      deleteOrderDoorConfiguration: DeleteOrderDoorConfigurationResult
    }

    export const execute = (variables: Variables) => appClient
      .mutate<Data, Variables>({ mutation, variables })
      .then(r => r.data?.deleteOrderDoorConfiguration.success
        ? true
        : ulog('e', 'GQLConfiguration(D) failed', r)
      )
      .catch(e => ulog('e', 'GQLConfiguration(D) exception', e))

    export const procedure = (fullpath: string) => execute({ fullpath })

  }

}
