import { PimcoreNode } from '@/models/pimcore/PimcoreNode'

export interface InvoiceData extends PimcoreNode {
  proformaLabel: string
  proformaColor: string
  isProformaIssued: boolean
  isProformaPaid: boolean
  invoiceLabel: string
  invoiceColor: string
  isInvoiceIssued: boolean
  isInvoicePaid: boolean
}

export const InvoiceDataMapper = (item: any): InvoiceData => ({
  ...PimcoreNode(item?.id, item?.key, item?.fullpath),
  proformaLabel: item?.proformaLabel ?? '',
  proformaColor: item?.proformaColor ?? '',
  isProformaIssued: item?.isProformaIssued ?? false,
  isProformaPaid: item?.isProformaPaid ?? false,
  invoiceLabel: item?.invoiceLabel ?? '',
  invoiceColor: item?.invoiceColor ?? '',
  isInvoiceIssued: item?.isInvoiceIssued ?? false,
  isInvoicePaid: item?.isInvoicePaid ?? false,
})
