import { UserRole } from '@/common/logic/auth/roles.enum'
import { AdminRoutes, AllRoutes, BranchRoutes, GeneralRoutes, RE, RouteEntry, withIcon, WorkerRoutes } from './interface'


// ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---
// ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---


/** General Route Entries */
export const general_re: GeneralRoutes = {
  index:                RE('n', 'index',                '',                     '/',                      '/'),

  admin:                RE('A', 'admin',                '',                     '/admin',                 '/admin'),
  montaznik:            RE('W', 'montaznik',            'Montážnik',            '/montaznik',             '/montaznik'),
  pobocka:              RE('B', 'pobocka',              'Pobočka',              '/pobocka',               '/pobocka'),
  editOrCreateOrder:    RE('a', 'editOrCreateOrder',    'Objednávka',           '/objednavka'),

  prihlaseni:           RE('n', 'prihlaseni',           'Přihlášení',           '/prihlaseni',            '/prihlaseni'),
  zapomenute_heslo:     RE('n', 'zapomenute_heslo',     'Zapomenuté Heslo',     '/zapomenute_heslo',      '/zapomenute_heslo'),
  obnova_hesla:         RE('n', 'obnova_hesla',         'Obnova hesla',         '/obnova_hesla',          '/obnova_hesla'),
  potvrzeni_objednavky: RE('n', 'potvrzeni_objednavky', 'Potvrzení Objednávky', '/potvrzeni_objednavky',  '/potvrzeni_objednavky'),
  potvrzeni_servisu:    RE('n', 'potvrzeni_servisu', 'Potvrzení Objednávky', '/potvrzeni_servisu',  '/potvrzeni_servisu'),
  potvrzeni_nabidky:    RE('n', 'potvrzeni_nabidky', 'Potvrzení nabídky', '/potvrzeni_nabidky',  '/potvrzeni_nabidky'),

  debug:                RE('n', 'debug',                'Debug',                '/debug',                 '/debug'),
  dev:                  RE('n', 'dev',                  'Dev',                  '/dev',                   '/dev'),
  notfound:             RE('n', 'notfound',             '404',                  '/404',                   '/:catchAll(.*)'), // '/:catchAll(.*)'  || '/:pathMatch(.*)*'
}


// ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---
// ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---


/** Admin Route Names */
export const admin_re: AdminRoutes = {
  admin_objednavky:               RE('A', 'admin_objednavky',               'Objednávky',               '/admin/objednavky'),
  admin_detail_objednavky:        RE('A', 'admin_detail_objednavky',        'Detail Objednávky',        '/admin/detail_objednavky'),

  admin_poptavky:                 RE('A', 'admin_poptavky',                 'Poptávky',                 '/admin/poptavky'),
  admin_detail_poptavky:          RE('A', 'admin_detail_poptavky',          'Detail Poptávky',          '/admin/detail_poptavky'),

  admin_kos_pruseru:              RE('A', 'admin_kos_pruseru',              'Průsery',              '/admin/kos_pruseru'),
  admin_detail_reklamace:         RE('A', 'admin_detail_reklamace',         'Reklamace',                '/admin/detail_reklamace'),
  admin_nova_reklamace:           RE('A', 'admin_nova_reklamace',         'Reklamace',                '/admin/nova_reklamace'),

  admin_reklamacni_protokol:      RE('A', 'admin_reklamacni_protokol',      'Reklamační protokol',      '/admin/reklamacni_protokol'),
  admin_reklamacni_protokol_nahrat:      RE('A', 'admin_reklamacni_protokol_nahrat',      'Reklamační protokol nahrát',      '/admin/reklamacni_protokol_nahrat'),

  admin_montazni_kalendar:        RE('A', 'admin_montazni_kalendar',        'Montážní Kalendář',        '/admin/montazni_kalendar'),

  admin_volno:                    RE('A', 'admin_volno',                    'Volno',                    '/admin/volno'),

  admin_montazni_skupiny:         RE('A', 'admin_montazni_skupiny',         'Montážní skupiny',         '/admin/montazni_skupiny'),
  admin_detail_montazni_skupiny:  RE('A', 'admin_detail_montazni_skupiny',  'Detail montážní skupiny',  '/admin/detail_montazni_skupiny'),

  admin_pobocky:                  RE('A', 'admin_pobocky',                  'Pobočky',                  '/admin/pobocky'),
  admin_detail_pobocky:           RE('A', 'admin_detail_pobocky',           'Detail Pobočky',           '/admin/detail_pobocky'),

  admin_predavaci_protokol:       RE('A', 'admin_predavaci_protokol',       'Montážní protokol',        '/admin/predavaci_protokol'),

  admin_sklad:                    RE('A', 'admin_sklad',                    'Sklad',                    '/admin/sklad'),
  admin_prevodky:                 RE('A', 'admin_prevodky',                 'Převodky',                 '/admin/prevodky'),
  admin_detail_prevodky:          RE('A', 'admin_detail_prevodky',          'Detail Převodky',          '/admin/detail_prevodky'),

  admin_uzivatele:                RE('A', 'admin_uzivatele',                'Uživatelé',                '/admin/users'),
  admin_nastaveni:                RE('A', 'admin_nastaveni',                'Nastavení',                '/admin/nastaveni'),
  admin_upload_dokumentu:         RE('A', 'admin_upload_dokumentu',         'Nahrání dokumentů',        '/admin/upload_dokumentu'),
}


// ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---
// ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---


export const worker_re: WorkerRoutes = {
  worker_objednavky:          RE('W', 'worker_objednavky',          'Objednávky',         '/montaznik/objednavky'),
  worker_detail_objednavky:   RE('W', 'worker_detail_objednavky',   'Detail Objednávky',  '/montaznik/detail_objednavky'),
  worker_montazni_kalendar:   RE('W', 'worker_montazni_kalendar',   'Montážní Kalendář',  '/montaznik/montazni_kalendar'),
  worker_predavaci_protokol:  RE('W', 'worker_predavaci_protokol',  'Předávací Protokol', '/montaznik/predavaci_protokol'),
  worker_sklad:               RE('W', 'worker_sklad',               'Sklad',              '/montaznik/sklad'),
  worker_volno:               RE('W', 'worker_volno',               'Volno',              '/montaznik/volno'),
  worker_vykazy:              RE('W', 'worker_vykazy',              'Výkazy',             '/montaznik/vykazy'),
  worker_detail_vykazu:       RE('W', 'worker_detail_vykazu',       'Detail Výkazu',      '/montaznik/detail_vykazu'),
  worker_nastaveni:           RE('W', 'worker_nastaveni',           'Montážní Skupina',   '/montaznik/nastaveni'),
  worker_upload_dokumentu:    RE('W', 'worker_upload_dokumentu',    'Nahrání dokumentů',  '/montaznik/upload_dokumentu'),

  worker_kos_pruseru:              RE('W', 'worker_kos_pruseru',              'Průsery',              '/montaznik/kos_pruseru'),
  worker_detail_reklamace:         RE('W', 'worker_detail_reklamace',         'Reklamace',                '/montaznik/detail_reklamace'),
  worker_nova_reklamace:           RE('W', 'worker_nova_reklamace',         'Reklamace',                '/montaznik/nova_reklamace'),
  worker_reklamacni_protokol:      RE('W', 'worker_reklamacni_protokol',      'Reklamační protokol',      '/montaznik/reklamacni_protokol'),
  worker_reklamacni_protokol_nahrat:      RE('W', 'worker_reklamacni_protokol_nahrat',      'Reklamační protokol nahrát',      '/montaznik/reklamacni_protokol_nahrat'),
}

// ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---
// ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---

export const branch_re: BranchRoutes = {
  branch_objednavky:        RE('B', 'branch_objednavky',        'Objednávky',         '/pobocka/objednavky'),
  branch_detail_objednavky: RE('B', 'branch_detail_objednavky', 'Detail Objednávky',  '/pobocka/detail_objednavky'),
  branch_poptavky:          RE('B', 'branch_poptavky',          'Poptávky',           '/pobocka/poptavky'),
  branch_detail_poptavky:   RE('B', 'branch_detail_poptavky',   'Detail Poptávky',    '/pobocka/detail_poptavky'),
  branch_montazni_kalendar: RE('B', 'branch_montazni_kalendar', 'Montážní kalendář',  '/pobocka/montazni_kalendar'),
  branch_sklad:             RE('B', 'branch_sklad',             'Sklad',              '/pobocka/sklad'),
  branch_vykazy:            RE('B', 'branch_vykazy',            'Výkazy',             '/pobocka/vykazy'),
  branch_upload_dokumentu:  RE('B', 'branch_upload_dokumentu',  'Nahrání dokumentů',  '/pobocka/upload_dokumentu'),
  branch_predavaci_protokol:RE('B', 'branch_predavaci_protokol', 'Předávací Protokol',  '/pobocka/predavaci_protokol'),
  branch_montazni_skupiny:         RE('B', 'branch_montazni_skupiny',         'Montážní skupiny',         '/pobocka/montazni_skupiny'),
  branch_detail_montazni_skupiny:  RE('B', 'branch_detail_montazni_skupiny',  'Detail montážní skupiny',  '/pobocka/detail_montazni_skupiny'),
  branch_pobocky:                  RE('B', 'branch_pobocky',                  'Pobočky',                  '/pobocka/pobocky'),
  branch_detail_pobocky:           RE('B', 'branch_detail_pobocky',           'Detail Pobočky',           '/pobocka/detail_pobocky'),
  branch_uzivatele:           RE('B', 'branch_uzivatele',           'Uživatelé',           '/pobocka/uzivatele'),

  branch_kos_pruseru:              RE('B', 'branch_kos_pruseru',              'Průsery',              '/pobocka/kos_pruseru'),
  branch_detail_reklamace:         RE('B', 'branch_detail_reklamace',         'Reklamace',                '/pobocka/detail_reklamace'),
  branch_nova_reklamace:         RE('B', 'branch_nova_reklamace',         'Reklamace',                '/pobocka/nova_reklamace'),
  branch_reklamacni_protokol:      RE('B', 'branch_reklamacni_protokol',      'Reklamační protokol',      '/pobocka/reklamacni_protokol'),
  branch_reklamacni_protokol_nahrat:      RE('B', 'branch_reklamacni_protokol_nahrat',      'Reklamační protokol nahrát',      '/pobocka/reklamacni_protokol_nahrat'),

}

// ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---
// ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---

/** Route Entries */
export const re: AllRoutes = {
  ...general_re,
  ...admin_re,
  ...worker_re,
  ...branch_re,
}
export const routeEntries = re
export const routeEntriesArr = Object.keys(re).map<RouteEntry>(key => re[key])


// ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---
// ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---


export const appToolbarMap: Record<UserRole, RouteEntry[]> = {
  none:         [],
  ht_admin:     [re.admin_objednavky, re.admin_poptavky, re.admin_montazni_kalendar, re.admin_kos_pruseru/* , re.admin_volno, re.admin_sklad */],
  ht_billing:   [re.admin_objednavky, re.admin_poptavky, re.admin_montazni_kalendar, re.admin_kos_pruseru/* , re.admin_volno, re.admin_sklad */],
  branch_montage_ht: [re.branch_objednavky, re.branch_poptavky, re.branch_kos_pruseru/* re.branch_vykazy, re.branch_sklad */],
  branch_montage_branch:  [re.branch_objednavky, re.branch_poptavky, re.branch_montazni_kalendar, re.branch_kos_pruseru/* , re.branch_vykazy, re.branch_sklad */],
  worker:       [re.worker_objednavky, re.worker_montazni_kalendar, re.worker_kos_pruseru/* , re.worker_volno, re.worker_vykazy, re.worker_sklad */],
}


// ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---
// ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---


const htSettings = [
  withIcon(re.admin_montazni_skupiny, 'mdi-account-hard-hat'),
  withIcon(re.admin_pobocky, 'mdi-domain'),
  withIcon(re.admin_uzivatele, 'mdi-account-group'),
  withIcon(re.admin_nastaveni, 'mdi-tune-vertical'),
]

const branchSettings = [
  withIcon(re.branch_pobocky, 'mdi-domain'),
  withIcon(re.branch_uzivatele, 'mdi-account-group'),
]
const branchSettings_montage_branch = [
  withIcon(re.branch_montazni_skupiny, 'mdi-account-hard-hat'),
  withIcon(re.branch_pobocky, 'mdi-domain'),
  withIcon(re.branch_uzivatele, 'mdi-account-group'),
]

export const appToolbarSettingsMap: Record<UserRole, RouteEntry[]> = {
  none:         [],
  ht_admin:     htSettings,
  ht_billing:   htSettings,
  branch_montage_ht: branchSettings,
  branch_montage_branch:  branchSettings_montage_branch,
  worker:       [withIcon(re.worker_nastaveni, 'mdi-account-group')],
}

export const routeTitles = {
  undef: 'HTDveře Objednávkový Systém',
  prefix: 'HTDveře',
}
