import { Maybe, MaybeNull } from '@/common/_types'
import { PimcoreNode } from '@/models/pimcore/PimcoreNode'

export const mapArrayItems = <InputType, OutputType>(data: Maybe<Array<Maybe<InputType>>>, mapper: (item: Maybe<InputType>) => Maybe<OutputType>) => {
  if (data == null || !Array.isArray(data)) return []
  return data.map(mapper).filter((item): item is OutputType => !!item)
}

export const appBaseUrl = () => {
  return `${window.location.protocol}//${window.location.host}`
}

export const downloadFileUrl = (url: string) => {
  const element = document.createElement('a')

  element.href = url
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

export const getObjectNameFromType = (object: MaybeNull<PimcoreNode>, typeKey = '__typename') => {
  return object?.[typeKey]?.replace('object_', '')?.toLowerCase() || 'object'
}
