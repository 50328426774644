import gql from 'graphql-tag'
import { appClient, GQLClient, publicClient } from '@/plugins/vue-apollo.plugin'
import { VueGQLType } from '@/common/custom/vue-apollo-customization'
import { PimcoreOrderAction, PimcoreActionsImport, PimcoreActionEntry } from '../../../models/order/workflow/Actions'
import { rlog } from '@/common/utils/AppConsole'
import { StateOrder } from '@/models/order/workflow/States'
import { ApplyOrderActionResult, OrderAllActionsListType } from '../schema/app.graphql.types'
import { Order } from '@/models/order/Order'

export namespace GQLOrderActions {

  export type Error = 'NOTFOUND' | 'NOTENABLED'

  export namespace Get {
    export type Type = VueGQLType<Data, Variables>
    export const isError = (o: PimcoreActionEntry[] | Error): o is Error => typeof o === 'string' && (o === 'NOTENABLED' || o === 'NOTFOUND')

    const query = gql`
      query GetOrderActions($orderId: Int!) {
        getAllOrderActions(orderId: $orderId) {
          actions {
            action
            enabled
          }
        }
      }
    `

    export type Variables = {
      orderId: number
    }
    export type Data = {
      getAllOrderActions?: OrderAllActionsListType
    }

    const execute = (client: GQLClient, variables: Variables) => client
      .query<Data, Variables>({ query, variables, fetchPolicy: 'no-cache' })
      .then(r => r.data.getAllOrderActions && r.data.getAllOrderActions.actions
        ? PimcoreActionsImport<PimcoreOrderAction>(r.data.getAllOrderActions.actions)
        : <Error>'NOTFOUND'
      )
      .catch(e => rlog<Error>('e', 'NOTFOUND', 'GQLOrderActions(Get) exception', e))

    export const procedure = async (order: Order) => order.id ? execute(appClient, { orderId: parseInt(order.id) }) : <Error>'NOTFOUND'

    export const publicProcedure = async (order: Order) => order.id ? execute(publicClient, { orderId: parseInt(order.id) }) : <Error>'NOTFOUND'

  }

  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---
  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---
  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---


  export namespace GetPreview {
    export type Type = VueGQLType<Data, Variables>
    export const isError = (o: PimcoreActionEntry[] | Error): o is Error => typeof o === 'string' && (o === 'NOTENABLED' || o === 'NOTFOUND')

    const query = gql`
      query GetAllOrderActionsPreview($hash: String!) {
        getAllOrderActionsPreview(hash: $hash) {
          actions {
            action
            enabled
          }
        }
      }
    `

    export type Variables = {
      hash: string
    }
    export type Data = {
      getAllOrderActionsPreview?: OrderAllActionsListType
    }

    const execute = (client: GQLClient, variables: Variables) => client
      .query<Data, Variables>({ query, variables, fetchPolicy: 'no-cache' })
      .then(r => r.data.getAllOrderActionsPreview && r.data.getAllOrderActionsPreview.actions
        ? PimcoreActionsImport<PimcoreOrderAction>(r.data.getAllOrderActionsPreview.actions)
        : <Error>'NOTENABLED'
      )
      .catch(e => rlog<Error>('e', 'NOTFOUND', 'GQLOrderActions(Get) exception', e))

    export const procedure = async (hash: string) => hash ? execute(appClient, { hash: hash }) : <Error>'NOTFOUND'
    export const publicProcedure = async (hash: string) => hash ? execute(publicClient, { hash: hash }) : <Error>'NOTFOUND'
  }



  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---
  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---
  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---

  export namespace Apply {
    export type Type = VueGQLType<Data, Variables>
    export const isError = (o: StateOrder | Error): o is Error => typeof o === 'string' && (o === 'NOTENABLED' || o === 'NOTFOUND')

    const mutation = gql`
      mutation ApplyOrderAction($orderId: Int!, $action: String!) {
        applyOrderAction(orderId: $orderId, action: $action) {
          success
          message
          output
        }
      }
    `

    export type Variables = {
      orderId: number
      action: PimcoreOrderAction
    }
    export type Data = {
      applyOrderAction: ApplyOrderActionResult
    }

    const execute = (client: GQLClient, variables: Variables): Promise<ApplyOrderActionResult> => client
      .mutate<Data, Variables>({ mutation, variables })
      .then(r => r.data!.applyOrderAction)

    export const procedure = async (order: Order, action: PimcoreOrderAction) =>
      order.id ? execute(appClient, { orderId: parseInt(order.id), action }) : null

    export const publicProcedure = async (order: Order, action: PimcoreOrderAction) =>
      order.id ? execute(publicClient, { orderId: parseInt(order.id), action }) : null

  }

  export namespace ApplyPreview {
    export type Type = VueGQLType<Data, Variables>
    export const isError = (o: StateOrder | Error): o is Error => typeof o === 'string' && (o === 'NOTENABLED' || o === 'NOTFOUND')

    const mutation = gql`
      mutation ApplyOrderAction($hash: String!, $action: String!) {
        applyOrderActionPreview(hash: $hash, action: $action) {
          success
          message
          output
        }
      }
    `

    export type Variables = {
      hash: string
      action: PimcoreOrderAction
    }
    export type Data = {
      applyOrderActionPreview: ApplyOrderActionResult
    }

    const execute = (client: GQLClient, variables: Variables): Promise<StateOrder | Error> => client
      .mutate<Data, Variables>({ mutation, variables })
      .then(r => r.data?.applyOrderActionPreview.success && !!r.data.applyOrderActionPreview.output
        ? r.data.applyOrderActionPreview.output as StateOrder
        : <Error>'NOTENABLED'
      )
      .catch(e => rlog<Error>('e', 'NOTFOUND', 'GQLOrderActions(Apply) exception', e))

    export const procedure = async (hash: string, action: PimcoreOrderAction) =>
      hash ? execute(appClient, { hash, action }) : <Error>'NOTFOUND'

    export const publicProcedure = async (hash: string, action: PimcoreOrderAction) =>
      hash ? execute(publicClient, { hash, action }) : <Error>'NOTFOUND'

  }

}
